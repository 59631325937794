import {
    Component,
    DestroyRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import Color from 'color';
import { ApplyModelDialog } from 'src/app/dialogs/apply-model-dialog/apply-model-dialog.component';
import { SelectOrganizationDialog } from 'src/app/dialogs/select-organization-dialog/select-organization-dialog.component';
import { ApiService } from 'src/app/services/api/api.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { tryParseNumber } from 'src/app/shared/common';
import { Organization } from 'src/app/shared/models/customer';
import { Model } from 'src/app/shared/models/model';
import { palette } from 'src/app/shared/palette';
import {
    ModelConfig,
    OrganizationConfig,
    SelectConfig,
    Setting,
    SettingType,
    TextConfig,
} from '../settings.model';

const onlyWhitespacesValidator = (
    control: AbstractControl,
): ValidationErrors | null => {
    return /[^\s]/.test(control.value) ? null : { notOnlyWhitespaces: true };
};

@Component({
    selector: 'sz-setting-item',
    templateUrl: './setting-item.component.html',
})
export class SettingItemComponent implements OnInit {
    private dialog = inject(DialogService);
    private api = inject(ApiService);
    private destroyRef = inject(DestroyRef);

    @Input() setting: Setting;
    @Input()
    set value(newValue) {
        this.formGroup.get('setting').setValue(newValue);
        if (this.setting.type === SettingType.Model) {
            this.entity = this.api.models.get(newValue);
        } else if (this.setting.type === SettingType.Organization) {
            this.entity = this.api.organizations.get(newValue);
        }
        this.valueChange.emit(newValue);
    }
    @Output() valueChange = new EventEmitter();

    types = SettingType;
    entity: Model | Organization = null;
    formGroup = new FormGroup({ setting: new FormControl(null) });
    errorMessages: Record<string, () => string> = {};

    // Get the color palette options.
    get palette() {
        const colorArray = Object.values(palette).map((c) => {
            const variants = [];
            const range = 4;
            for (let v = -range; v <= range; v++) {
                const newColor = Color(c).darken(v * 0.15);
                variants.push(newColor.hex());
            }
            return { preview: c, variants };
        });
        return colorArray;
    }

    // Get the customer ID.
    get faviconCustomerId() {
        return this.setting.config && 'faviconCustomerId' in this.setting.config
            ? this.setting.config.faviconCustomerId
            : 0;
    }

    ngOnInit() {
        const validators = [];
        let convertValue = (value) => value;
        if (this.setting.type == SettingType.Text) {
            const config = this.setting.config as TextConfig;
            if (config?.errorMessages) {
                this.errorMessages = config?.errorMessages;
            }
            if (this.setting.required != null) {
                this.errorMessages = {
                    ...this.errorMessages,
                    notOnlyWhitespaces: () => 'form.error.required',
                };
                validators.push(Validators.required, onlyWhitespacesValidator);
            }
            if (config?.minimum != null) {
                validators.push(Validators.min(config.minimum));
            }
            if (config?.maximum != null) {
                validators.push(Validators.max(config.maximum));
            }
            if (config?.pattern != null) {
                validators.push(Validators.pattern(config.pattern));
            }
            if (config?.type === 'number') {
                convertValue = tryParseNumber;
            }
        }
        if (this.setting.type == SettingType.Select) {
            if (this.setting?.required) {
                validators.push(Validators.required);
            }
        }
        const settingControl = this.formGroup.get('setting');
        settingControl.addValidators(validators);
        if (this.setting.disabled) {
            settingControl.disable();
        }
        settingControl.valueChanges
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((value) => {
                if (settingControl.valid) {
                    this.valueChange.emit(convertValue(value));
                }
            });
    }

    async changeEntity() {
        let dialog = null;
        const data = { data: {} };
        const value = this.formGroup.get('setting').value;
        if (this.setting.type === SettingType.Model) {
            const modelConfig = this.setting?.config as ModelConfig;
            dialog = ApplyModelDialog;
            data.data = { models: modelConfig?.models, modelId: value };
        } else if (this.setting.type === SettingType.Organization) {
            const organizationConfig = this.setting
                ?.config as OrganizationConfig;
            const onlyRoles = organizationConfig?.onlyRoles;
            dialog = SelectOrganizationDialog;
            data.data = {
                title: this.setting.title,
                onlyRoles,
                organizations: organizationConfig?.organizations,
                organizationId: value,
            };
        }
        const entity: { id: number } = await this.dialog.open(dialog, data);
        if (entity) {
            this.valueChange.emit(entity.id);
        }
    }
}
